import React, { useState } from "react"

import {
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  Collapse,
  Link
} from "@mui/material"
import ExpandLess from "@mui/icons-material/ExpandLess"
import ExpandMore from "@mui/icons-material/ExpandMore"



function ExperienceItem(role) {
  const job = role.role
  const [open, setOpen] = useState(false)

  const handleClick = () => {
    setOpen(!open)
  }

  return (
    <ListItem>
      <ListItemText
        primary={
          <Box>
            <Link href={job.url}>
              <Typography variant="h6">{job.company}</Typography>
            </Link>

            <Typography sx={{ ml: 2 }}>
              <i>{job.title}</i>
            </Typography>
            <Typography color="text.secondary" sx={{ ml: 2 }}>
              {job.dates}
            </Typography>
          </Box>
        }
        secondary={
          <Box>
            <Typography color="text.primary">{job.description}</Typography>
            <List>
              {job.projects && (
                <ListItem onClick={handleClick}>
                 Projects {open ? <ExpandLess  sx={{position:'absolute', right: 0}}/> : <ExpandMore  sx={{position:'absolute', right: 0}}/>}
                </ListItem>
              )}
              {job.projects ? (
                job.projects.map(project => (
                  <Collapse in={open} timeout="auto" unmountOnExit>
                    <ListItem component="div" id={project.title}>
                      <ListItemText
                        primary={
                          <Box><Link to href={project.url}>
                            <Typography variant="h6" color="text.primary">
                              {project.title}
                            </Typography>
                            </Link>
                            <Typography>{project.description}</Typography>
                          </Box>
                        }
                        secondary={
                          <List disablePadding="true">
                            {project.technologies && (
                              <ListItem>
                                Technologies: {project.technologies}
                              </ListItem>
                            )}
                            {project.client && (
                              <ListItem>Client: {project.client}</ListItem>
                            )}
                            {project.responsibilities && (
                              <ListItem>
                                Responsibilities: {project.responsibilities}
                              </ListItem>
                            )}
                          </List>
                        }
                      />
                    </ListItem>
                  </Collapse>
                ))
              ) : (
                <></>
              )}
            </List>
          </Box>
        }
      />
    </ListItem>
  )
}

export default ExperienceItem
