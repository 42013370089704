import { Box, Container, Typography } from "@mui/material";

// Components and Pages ----------------------------------
import Nav from "../nav/Nav";

export default function Home() {
  return (
    <Container maxWidth="false">
      <Nav />
      <Box>
        <Typography variant="h1" sx={{ mt: "18vw", ml: "18vw" }}>
          Meir David Sedlis
        </Typography>
        <Typography variant="h4" color="text.secondary" align="center">
          a nice guy
        </Typography>
      </Box>
    </Container>
  );
}
