import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import {initializeApp} from 'firebase/app';
import { getAnalytics } from "firebase/analytics";

import { ThemeProvider, Paper } from "@mui/material";

import Resume from "./routes/resume/Resume";
import Allyx from "./routes/allyx/Allyx";
import Theme from "./styles/Theme";
import Background from "./assets/background.jpeg";
import SymptomTracker from "./routes/symptomTracker/SymptomTracker";

const firebaseConfig = {
  apiKey: "AIzaSyDUD6lYDywsto-ltlqBozBBHsGKxpHNWTw",
  authDomain: "meir-portfolio.firebaseapp.com",
  projectId: "meir-portfolio",
  storageBucket: "meir-portfolio.appspot.com",
  messagingSenderId: "277330542549",
  appId: "1:277330542549:web:941d291e592de5b2ea075c",
  measurementId: "G-LPJ2R37SDQ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// eslint-disable-next-line
const analytics = getAnalytics(app);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Paper
      variant="background"
      sx={{
        backgroundImage: `url(${Background})`,
        backgroundPosition: "center",
        backgroundAttachment: "fixed",
        overflow: "scroll",
        backgroundRepeat: "no-repeat",
        overscrollBehavior: "none",
        backgroundSize: "cover",
        height: "100vh",
        mt: 0
      }}
    >
      <ThemeProvider theme={Theme}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<App />} />
            <Route path="/resume" element={<Resume />} />
            <Route path="/allyx" element={<Allyx />} />
            <Route path="/symptom-tracker" element={<SymptomTracker />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </Paper>
  </React.StrictMode>
);
