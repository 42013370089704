import { createTheme } from "@mui/material/styles";

const Theme = createTheme({
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          marginTop: "1%",
          marginLeft: 0,
          marginRight: 0,
        },
      },
    }
  },
  typography: {
    fontSize: 18,
    lineHeight: 1.8,
    h1: {
      fontSize: "5rem",
    },
    fontFamily: "Jost",
    h2: {
      fontSize: "4rem",
    },
    h3: {
      fontSize: "3rem",
    },
    h4: {
      fontSize: "2.5rem",
    },
    h5: {
      fontSize: "2.25rem",
    },
    h6: {
      fontSize: "2rem",
    },
  },
});


export default Theme;
