import { Outlet} from "react-router-dom"

import {Box} from '@mui/material'

import Home from "./routes/home/Home"


function App() {
  return (
    <Box>
      <Home/>
      <Outlet/>
    </Box>
  );
}

export default App;
