// MUI -------------
import {
  Box,
  Container,
  Typography,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
  List,
  Paper,
} from "@mui/material";

// components -------------
import "../../styles/resume.css";
import ExperienceItem from "./components/ExperienceItem";
import Nav from "../nav/Nav";

// assets -------------
import HTMLIcon from "../../assets/resume/html.png";
import CSSIcon from "../../assets/resume/css3.png";
import JavascriptIcon from "../../assets/resume/javascript.png";
import ReactIcon from "../../assets/resume/react.png";
import ReduxIcon from "../../assets/resume/redux.png";
import SagaIcon from "../../assets/resume/reduxsaga.png";
import PostgreSQLIcon from "../../assets/resume/postgresql.png";
import GitIcon from "../../assets/resume/git.png";
import GHIcon from "../../assets/resume/ghlogo.png";
import APIIcon from "../../assets/resume/api.png";
import jQueryIcon from "../../assets/resume/jquery.png";
import ExpressIcon from "../../assets/resume/express.png";
import NodeIcon from "../../assets/resume/nodejs.png";
import SQLIcon from "../../assets/resume/sql.png";
import CSharpIcon from "../../assets/resume/csharp.png";
import MUIIcon from "../../assets/resume/MUI.png";
import BootstrapIcon from "../../assets/resume/bootstrap.png";
import SpanishIcon from "../../assets/resume/spain.png";
import RussianIcon from "../../assets/resume/russia.png";
import PrimeLogo from "../../assets/resume/prime.png";
import UMN from "../../assets/resume/UMN.png";
import Python from "../../assets/resume/python.png";

// data -------------

function Resume() {
  const skills = [
    // { icon: HTMLIcon, skill: "HTML" },
    // { icon: CSSIcon, skill: "CSS" },
    { icon: JavascriptIcon, skill: "Javascript" },
    { icon: ReactIcon, skill: "React" },
    { icon: ReduxIcon, skill: "React Redux" },
    { icon: SagaIcon, skill: "Redux Saga" },
    { icon: Python, skill: "Python" },
    { icon: PostgreSQLIcon, skill: "PostgreSQL" },
    { icon: GitIcon, skill: "Git" },
    { icon: GHIcon, skill: "GitHub" },
    { icon: APIIcon, skill: "APIs" },
    { icon: jQueryIcon, skill: "jQuery" },
    { icon: ExpressIcon, skill: "Express" },
    { icon: NodeIcon, skill: "Node.js" },
    { icon: SQLIcon, skill: "SQL" },
    { icon: CSharpIcon, skill: " C#" },
    { icon: MUIIcon, skill: "Material UI" },
    { icon: BootstrapIcon, skill: "Bootstrap CSS" },
    { icon: SpanishIcon, skill: "Spanish" },
    { icon: RussianIcon, skill: "Russian" },
  ];

  const experience = [
    {
      company: "Prime Digital Academy",
      location: "Minneapolis, MN",
      url: "https://www.primeacademy.io/courses/engineering",
      title: "Full Stack Software Engineering Student",
      dates: "February 2022 - July 2022",
      description:
        "Five month, immersive training program with emphasis on DEI, public speaking, and app development. Bi-weekly professional skills practice fostered collaboration with other engineers as well as UX trainees.",
      projects: [
        {
          title: "Symptom Tracker",
          url: "/symptom-tracker",
          description:
            "My first solo project, Symptom Tracker is a patient-provider communication tool designed to help patients be better advocates for their own health and provide clinicians with more accurate data about what’s going on in between office visits. Designed to be used when users are feeling crummy, symptom tracker is a lightweight, easy to use platform that provides users with meaningful data about the problems they’re tracking to help guide conversations with their healthcare provider.",
          technologies:
            "React, React Redux, Redux Saga, PostgreSQL, Express, Node.js, Material UI, Chart.js",
        },
        {
          title: "Allyx",
          url: "/allyx",
          description:
            "Designed as an alternative to traditional safety apps for independent women living in New York City, Allyx allows members to request phone calls from vetted Allies for virtual companionship while traveling at night. The app facilitates the application and vetting process for the volunteer Allies, and has a robust admin dashboard to gather data to support future funding opportunities. This app is a proof of concept built in collaboration with three other developers.",
          technologies:
            "React, React Redux, Redux Saga, PostgreSQL, Express, Node.js, Material UI, Cloudinary",
          client: "Alex Edmonson, Allyx",
          responsibilities:
            "Led client meetings, created wireframes in Figma, contributed code using Git and GitHub, built admin dashboard and majority of front end styling.",
        },
      ],
    },
    {
      company: "Deluxe Corporation",
      location: "Shoreview, MN",
      url: "https://www.deluxe.com/payments/digital/echecks/",
      title: "eCheck Support Specialist",
      dates: "February 2019 - September 2019",
      description:
        "Phone, email and chat based technical support for emailable checks. Case logging in Salesforce. Technical support for product at all levels.",
    },
    {
      company: "GeekHampton",
      location: "Sag Harbor, NY",
      url: "https://www.geekhampton.com/",
      title: "Technology Sales/Customer Service Specialist",
      dates: "May 2018 - October 2018",
      description:
        "Sales and repair intake for Apple devices and peripherals. Private lessons in Microsoft applications for Apple users. Remote desktop support for corporate clients. Navigate challenging customer service interactions in a fast-paced, high-end vacation destination.",
    },
    {
      company: "Provisions Natural Foods",
      location: "Sag Harbor, NY",
      url: "https://www.provisionsnaturalfoods.com/",
      title: "Clerk",
      dates: "October 2017 - March 2018",
      description:
        "Collaborate with team to keep sales floor and cafe stocked. Prepare food and beverages in the cafe and handle cleanup.",
    },
    {
      company: "Hampton Country Day Camp",
      location: "East Hampton, NY",
      url: "https://www.hamptoncountrydaycamp.com/",
      title: "Music Specialist",
      dates: "Summer 2017",
      description: "Plan and teach music classes for children aged 2-11.",
    },
    {
      company: "University of Minnesota Medical School",
      location: "Minneapolis, MN",
      url: "https://med.umn.edu/dom/divisions/general-internal-medicine",
      title: "Executive Office and Administrative Specialist",
      dates: "August 2013 - April 2017",
      description:
        "Office and administrative support including complex scheduling, travel, financial reporting, project and course support. Hiring and onboarding. Research support including survey creation and administration and all aspects of publishing. IT support including migration from POP to IMAP",
      projects: [
        {
          title: "Regional Coordinator: National TeamSTEPPS Implementation",
          url: "https://www.ahrq.gov/teamstepps/index.html",
          description:
            "Oversee four annual Master Training sessions for TeamSTEPPS in coordination with national partners. Travel for national meetings and conferences.",
        },
      ],
    },
    {
      company: "ChangeCommunications",
      location: "New York, NY",
      title: "Communications Associate",
      dates: "March 2011 - February 2013",
      description:
        "Assist with grant writing, draft grant applications. Compose and edit press releases, op-eds, website content and other material. Build media lists and disseminate press releases. Track deliverables, generate reports and serve as point of contact for clients.",
    },
  ];

  const certifications = [
    {
      certificate: "Full Stack Engineering Certificate",
      issuer: "Prime Digital Academy",
      date: "July 2022",
      logo: PrimeLogo,
    },
    {
      certificate: "Basic Equity and Diversity Certificate",
      issuer: "University of Minnesota",
      date: "Spring 2016",
      logo: UMN,
    },
  ];

  return (
    <Container maxWidth="100vw">
      <Nav />
      <Paper className="pageContent" sx={{ p: 2, bgcolor: "rgba(255, 255, 255, 0.8)" }}>
        <Typography variant="h3">About Meir</Typography>
        <Box className="resume-section">
          <Typography variant="h4">Summary</Typography>
          <Typography sx={{ p: 4 }} fontSize="1.25">
            I am a cheerful, dynamic and persistent problem solver. Software
            engineering allows me to use my passion for accessibility to create
            things that change the world. I’m a musician, former theater kid,
            and bad joke enthusiast.
          </Typography>
        </Box>
        <Box className="resume-section">
          <Typography variant="h4">Certifications</Typography>
          <List>
            {certifications.map((certification) => (
              <ListItem>
                <ListItemAvatar>
                  <img
                    src={certification.logo}
                    alt={certification.logo}
                    className="certification-logo"
                  />
                </ListItemAvatar>
                <ListItemText
                  className="certification-text"
                  primary={
                    <Box>
                      <Typography>{certification.certificate}</Typography>
                      <Typography>{certification.issuer}</Typography>
                    </Box>
                  }
                  secondary={certification.date}
                />
              </ListItem>
            ))}
          </List>
        </Box>
        <Box className="resume-section">
          <Typography variant="h4">Skills</Typography>
          <Grid container justifyContent="space-evenly" className="skill-list">
            {skills.map((skill) => (
              <List>
                <ListItem key={skill}>
                  <ListItemAvatar>
                    <img
                      src={skill.icon}
                      alt={skill.skill}
                      className="skill-icon"
                    />
                  </ListItemAvatar>
                  <ListItemText primary={skill.skill} />
                </ListItem>
              </List>
            ))}
          </Grid>
        </Box>
        <Box className="resume-section">
          <Typography variant="h4">Experience</Typography>
          <List dense="true">
            {experience.map((role) => (
              <ExperienceItem role={role} />
            ))}
          </List>
        </Box>
      </Paper>
    </Container>
  );
}

export default Resume;
