import { Typography } from "@mui/material";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineDot,
  TimelineContent,
  TimelineOppositeContent,
  TimelineConnector,
} from "@mui/lab";


export default function STTimeline() {
  return (
    <Timeline>
      <Typography align="center" variant="h5">
        Timeline
      </Typography>
      <TimelineItem>
        <TimelineOppositeContent color="text.secondary">
          May 16th, 2022
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>Begin planning, create scope document</TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent color="text.secondary">
          May 18th, 2022
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot variant="outlined"/>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>Submit scope document for approval</TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent color="text.secondary">
          May 20th, 2022
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot variant="outlined"/>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>Scope approved</TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent color="text.secondary">
          The next two weeks
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot variant="outlined"/>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>Build, test, repeat.</TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent color="text.secondary">
          June 6th, 2022
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot color="success"/>
        </TimelineSeparator>
        <TimelineContent>Demo final product</TimelineContent>
      </TimelineItem>

      <Typography align="center">
        Total development time: <strong>three weeks</strong>
      </Typography>
    </Timeline>
  );
}
