import React from "react"

import { Typography } from "@mui/material"
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineDot,
  TimelineContent,
  TimelineOppositeContent,
  TimelineConnector,
} from "@mui/lab"

export default function AllyxTimeline(){
    return(
        <Timeline>
            <Typography align="center" variant="h5">Timeline</Typography>
            <TimelineItem>
                <TimelineOppositeContent color="text.secondary">
                    June 10th, 2022
                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineDot />
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>Choose client, meet team</TimelineContent>
            </TimelineItem>
            <TimelineItem>
                <TimelineOppositeContent color="text.secondary">
                    June 13th, 2022
                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineDot variant="outlined"/>
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>Meet client, begin scoping app</TimelineContent>
            </TimelineItem>
            <TimelineItem>
                <TimelineOppositeContent color="text.secondary">
                    June 16th, 2022
                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineDot variant="outlined"/>
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>Scope approved by client, scrum kickoff</TimelineContent>
            </TimelineItem>
            <TimelineItem>
                <TimelineOppositeContent color="text.secondary">
                    The next two weeks
                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineDot variant="outlined"/>
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>Four day scrums with daily PR merges</TimelineContent>
            </TimelineItem>
            <TimelineItem>
                <TimelineOppositeContent color="text.secondary">
                    July 5th, 2022
                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineDot variant="outlined"/>
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>Demo final product</TimelineContent>
            </TimelineItem>
            <TimelineItem>
                <TimelineOppositeContent color="text.secondary">
                    July 6th, 2022
                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineDot color="success"/>
                </TimelineSeparator>
                <TimelineContent>Handoff to client</TimelineContent>
            </TimelineItem>
            <Typography align="center">Total development time: <strong>three weeks</strong></Typography>
        </Timeline>
    )
}