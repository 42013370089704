import {
  Box,
  Container,
  Typography,
  Grid,
  Paper,
  List,
  ListItem,
  ListItemText,
  Link,
} from "@mui/material";

// Components and Pages ----------------------------------
import Nav from "../nav/Nav";
import AllyxTimeline from "./components/AllyxTimeline";
import homeScreen from "../../assets/allyx/homeScreen.gif";
import scheduleACall from "../../assets/allyx/scheduleacall.gif";
import claimACall from "../../assets/allyx/claimacall.gif";
import adminDashboard from "../../assets/allyx/adminDashboard.png";

export default function Allyx() {
  return (
    <Container maxWidth="100vw">
      <Nav />

      <Paper
        className="pageContent"
        sx={{ p: 2, bgcolor: "rgba(255, 255, 255, 0.8)" }}
      >
        <Box>
          <Typography variant="h2">
            Project: <i>Allyx</i>
          </Typography>
          <Link
            color="text.disabled"
            underline="hover"
            href="https://github.com/allyxdevteam/AllyX"
            target="_blank"
            variant="h6"
          >
            GitHub Repository
          </Link>

          <Typography variant="h4" color="text.secondary" sx={{ ml: 8, mb: 8 }}>
            a proof of concept app built for a client in NYC
          </Typography>
        </Box>
        <Typography
          sx={{
            maxWidth: "80ch",
            lineHeight: 2,
            p: 5,
            m: "auto",
            bgcolor: "rgb(143,116,173, 0.5)",
          }}
        >
          <b>Allyx</b> is a virtual walking companion for independent women.
          Safety apps targeting this demographic have many barriers to use such
          as reliance on existing social networks, focus on impending danger,
          and a culture of fear and paranoia. Allyx addresses these issues by
          promoting community and individual connections between vetted
          strangers. This app is a proof of concept that Alex Edmonson, the
          founder, can use to seek funding and grow her product.
        </Typography>
        <Grid
          container
          id="allyx-intro"
          sx={{ mt: 8, mb: 1 }}
          columns={{ xs: 2, sm: 4, md: 12 }}
        >
          <Grid
            item
            justifyContent="center"
            sx={{ pt: 6 }}
            xs={2}
            sm={4}
            md={6}
          >
            <AllyxTimeline />
          </Grid>
          <Grid item sx={{ m: "auto" }} xs={2} sm={3} md={3}>
            <img
              className="projectGif"
              src={homeScreen}
              alt="allyx home screen. mobile app with a pink background and text that says welcome, we're so glad you're here."
            />
          </Grid>
        </Grid>
        <Typography
          align="center"
          variant="h2"
          sx={{ mt: 8, mb: 4, bgcolor: "rgb(143,116,173, 0.5)" }}
        >
          How it Works
        </Typography>
        <Grid
          container
          id="how-allyx-works"
          sx={{ mt: 4, mb: 1 }}
          columns={{ xs: 2, sm: 4, md: 12 }}
          spacing={6}
        >
          <Grid item sx={{ m: "auto" }} xs={2} sm={3} md={3}>
            <img
              className="projectGif"
              src={scheduleACall}
              alt="animated gif of a user scheduling a call using the allyx app"
            />
          </Grid>
          <Grid item xs={2} sm={3} md={3}>
            <Typography
              sx={{
                maxWidth: "80ch",
                lineHeight: 2,
                pt: 2,
              }}
            >
              Allyx allies are virtual travel companions. After a night out on
              the town, members connect with allies either by requesting to be
              called right away, or by scheduling a call like Rachel does in the
              demo seen here.
            </Typography>
            <Typography
              sx={{
                maxWidth: "80ch",
                lineHeight: 2,
                pt: 4,
              }}
            >
              Like most other members, Rachel is also an ally, so tonight before
              she goes out, she accepts a call request from another member, Nia.
            </Typography>
            <Typography
              sx={{
                maxWidth: "80ch",
                lineHeight: 2,
                pt: 4,
              }}
            >
              After marking the call complete, both users are prompted to leave
              a rating and review of their experience to help improve the app.
            </Typography>
          </Grid>
          <Grid item sx={{ m: "auto" }} xs={2} sm={3} md={3}>
            <img
              className="projectGif"
              src={claimACall}
              alt="animated gif of a user scheduling a call using the allyx app"
            />
          </Grid>
        </Grid>
        <Typography
          align="center"
          variant="h3"
          sx={{ mt: 8, mb: 4, bgcolor: "rgb(143,116,173, 0.5)" }}
        >
          For the Admin
        </Typography>
        <Typography
          sx={{
            maxWidth: "80ch",
            lineHeight: 2,
            m: "auto",
            pt: 4,
            pb: 4,
          }}
        >
          Because this app is a proof of concept, it was very important to me to
          create an admin dashboard that would allow my client to administer the
          site and download meaningful data that she could bring to potential
          investors to help shape the future of this project.
        </Typography>
        <Box display="grid" justifyItems="center">
          <img
            id="dashboardImg"
            src={adminDashboard}
            alt="admin dashboard on the allyx site, contains several tabs including users, comments, calls, ratings, problems and ally applications"
          />
        </Box>
        <Typography
          sx={{
            maxWidth: "80ch",
            lineHeight: 2,
            m: "auto",
            pt: 4,
          }}
        >
          To achieve this, I started with PostgreSQL and ensured that I had
          queries and corresponding server routes for any question about the app
          I thought might be answered with data.
        </Typography>
        <Typography
          sx={{
            maxWidth: "80ch",
            lineHeight: 2,
            m: "auto",
            pt: 4,
          }}
        >
          In some cases, I was able to call existing routes, but the responses
          weren't always meaningful. In those cases, I rewrote the queries with
          joins so that the results contained names and dates, not just IDs.
        </Typography>
        <Typography
          sx={{
            maxWidth: "80ch",
            lineHeight: 2,
            m: "auto",
            pt: 4,
          }}
        >
          My next step was to create a template for the report using DataGrid
          from MUI X. The documentation for DataGrid was honestly not as clear
          as what I've come to expect of MUI, and when I went searching for
          video tutorials on how to implement ProcessRowUpdates to get edits to
          persist, I didn't find anything helpful. It took a lot of trial and
          error and help from my instructor, Matt Black.
        </Typography>

        <Typography
          sx={{
            maxWidth: "80ch",
            lineHeight: 2,
            m: "auto",
            pt: 4,
          }}
        >
          Once I got it figured out, I was thrilled with how straightforward the
          process was from there.
        </Typography>
        <Typography
          sx={{
            maxWidth: "80ch",
            lineHeight: 2,
            m: "auto",
            pt: 4,
          }}
        >
          First I created a saga for the query I wanted to run, and a reducer to
          hold the data. Then I created a copy of my template report, updated
          the imports and dispatched that saga in the useEffect. Next, I
          configured the columns array, added any actions and alerts, and then
          tested the component.
        </Typography>
        <Typography
          sx={{
            maxWidth: "80ch",
            lineHeight: 2,
            m: "auto",
            pt: 4,
          }}
        >
          Given more time, I would have styled the dashboard differently than
          the rest of the site, as the text isn't as readable as I'd like and it
          is the only part of the site designed to be viewed on desktop, so
          different styling wouldn't be problematic.
        </Typography>
        <Typography
          align="center"
          variant="h3"
          sx={{ mt: 8, mb: 3, bgcolor: "rgb(143,116,173, 0.5)" }}
        >
          What else did you do, Meir?
        </Typography>
        <Typography
          sx={{
            maxWidth: "80ch",
            lineHeight: 2,
            m: "auto",
          }}
        >
          Allyx was definitely a team effort. I'm proud of the collaborative
          spirit of this dev team and everything we achieved. I took the lead on
          the following aspects of this project:
        </Typography>
        <Box display="grid" justifyItems="center" sx={{ pb: 6 }}>
          <List dense="true">
            <ListItem>
              <ListItemText primary="Wrote initial database.sql file" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Led client meetings" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Managed authentication (using Passport.js) and in-app routing for logged in users" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Built admin dashboard" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Built nav bar and call button" />
            </ListItem>
          </List>
        </Box>
      </Paper>
    </Container>
  );
}
