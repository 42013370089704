import {
  Box,
  Container,
  Typography,
  Grid,
  Paper,
  List,
  ListItem,
  ListItemText,
  Link,
} from "@mui/material";

// Components and Pages ----------------------------------
import Nav from "../nav/Nav";
import logInstance from "../../assets/symptomtracker/loginstance.gif";
import trackNew from "../../assets/symptomtracker/tracknew.gif";
import details from "../../assets/symptomtracker/details.png";
import homeImage from "../../assets/symptomtracker/home.png";
import STTimeline from "./components/STTimeline";

export default function SymptomTracker() {
  return (
    <Container maxWidth="100vw">
      <Nav />

      <Paper
        className="pageContent"
        sx={{ p: 2, bgcolor: "rgba(255, 255, 255, 0.8)" }}
      >
        <Box>
          <Typography variant="h2">
            Project: Symptom<i>Tracker</i>
          </Typography>
          <Link
            color="text.disabled"
            underline="hover"
            href="https://github.com/MeirSedlis/Symptom-Tracker"
            target="_blank"
            variant="h6"
          >
            GitHub Repository
          </Link>

          <Typography variant="h4" color="text.secondary" sx={{ ml: 8, mb: 8 }}>
            a lightweight tool to help guide conversations with your clinician
          </Typography>
        </Box>
        <Typography
          sx={{
            maxWidth: "80ch",
            lineHeight: 2,
            p: 7,
            m: "auto",
            bgcolor: "rgb(81,98,98)",
            color: "#f5f5f5",
       
          }}
        >
          <b>SymptomTracker</b> was my first solo full stack project. In 2020, I
          was diagnosed with a rare neurological condition that had debilitating
          physical symptoms, threatened my vision, and disoriented me to the
          point where I often had trouble describing my experiences to my
          healthcare providers. I developed SymptomTracker as the tool I wish
          I'd had while searching for a diagnosis.
        </Typography>
        <Grid
          container
          id="symptom-tracker-intro"
          sx={{ mt: 8, mb: 1 }}
          columns={{ xs: 2, sm: 4, md: 12 }}
        >
          <Grid item sx={{ m: "auto" }} xs={2} sm={3} md={3}>
            <img
              id="st-home-image"
              src={homeImage}
              alt="SymptomTracker home screen. Says Welcome, Seymour. This app is designed to facilitate conversations with your clinician, not replace them. There are two buttons, GET STARTED and LOG OUT"
            />
          </Grid>
          <Grid
            item
            justifyContent="center"
            sx={{ pt: 6 }}
            xs={2}
            sm={4}
            md={6}
          >
            <STTimeline />
          </Grid>
        </Grid>
        <Typography
          align="center"
          variant="h2"
          sx={{ mt: 8, mb: 0, bgcolor: "rgb(81,98,98, 0.9)", color: "#f5f5f5" }}
        >
          How it Works
        </Typography>
        <Grid
          container
          id="how-symptom-tracker-works"
          sx={{ mt: 0,}}
          columns={{ xs: 2, sm: 4, md: 12 }}
          spacing={6}
        >
          <Grid item sx={{ m: "auto" }} xs={3} sm={3} md={3}>
            <img
              className="projectGif"
              src={trackNew}
              alt="animated gif of a user tracking a new symptom"
            />
          </Grid>
          <Grid item xs={3} sm={3} md={4}>
            <Typography
              sx={{
                maxWidth: "80ch",
                lineHeight: 2,
                pt: 2,
              }}
            >
              When users log into SymptomTracker, they can add new symptoms from
              a predefined list, or add to that list if the symptom they want to
              track isn't included.{" "}
            </Typography>
            <Typography
              sx={{
                maxWidth: "80ch",
                lineHeight: 2,
                pt: 4,
              }}
            >
              To log an instance of a symptom, simply tap on the name of the
              symptom, and use the slider to rate the intensity on a scale of
              one to five. After tapping 'LOG' the instance is stored, and the
              user is free to close the app.
            </Typography>
            <Typography
              sx={{
                maxWidth: "80ch",
                lineHeight: 2,
                pt: 4,
                pb: 4,
              }}
            >
              The lack of feedback is intentional. I wanted as few interactions
              for the user to navigate as possible. For users with pain, I
              wanted a simple experience that would be useful with minimal user
              buy-in.
            </Typography>
          </Grid>
          <Grid item sx={{ m: "auto" }} xs={3} sm={3} md={3}>
            <img
              className="projectGif"
              src={logInstance}
              alt="animated gif of a user tracking a new instance of a symptom"
            />
          </Grid>
        </Grid>
        <Typography
          align="center"
          variant="h3"
          sx={{ mt: 8, mb: 4, bgcolor: "rgb(81,98,98, 0.9)", color: "#f5f5f5" }}
        >
          It's All in the Details
        </Typography>
        <Grid
          container
          id="symptom-tracker-details"
          sx={{ mt: 4, mb: 1 }}
          columns={{ xs: 2, sm: 4, md: 12 }}
          spacing={6}
        >
          <Grid item sx={{ m: "auto" }} xs={2} sm={2} md={2}>
            <Box display="grid" justifyContent="center">
              <img
                id="st-detail-image"
                src={details}
                alt="animated gif of a user tracking a new instance of a symptom"
              />
            </Box>
          </Grid>
          <Grid item xs={2} sm={3} md={6} sx={{pr:4}}>
            <Typography
              sx={{
                maxWidth: "80ch",
                lineHeight: 2,
                m: "auto",
                pt: 1,
              }}
            >
              The most valuable part of SymptomTracker is the details page.
            </Typography>
            <Typography
              sx={{
                maxWidth: "80ch",
                lineHeight: 2,
                m: "auto",
                pt: 4,
              }}
            >
              In a previous life, I worked as a regional coordinator for the
              national{" "}
              <a
                href="https://www.ahrq.gov/teamstepps/index.html"
                target="blank"
                color="inherit"
              >
                TeamSTEPPS
              </a>{" "}
              implementation. TeamSTEPPS is a federal program to improve patient
              outcomes by improving communication and teamwork skills among
              health care professionals.
            </Typography>
            <Typography
              sx={{
                maxWidth: "80ch",
                lineHeight: 2,
                m: "auto",
                pt: 4,
              }}
            >
              I used my experience listening to the needs and concerns of
              hundreds of clinicians during TeamSTEPPS Master Trainer courses to
              inform the choices I made for SymptomTracker.
            </Typography>

            <Typography
              sx={{
                maxWidth: "80ch",
                lineHeight: 2,
                m: "auto",
                pt: 4,
              }}
            >
              I wanted the user experience to be simple and trustworthy, without
              unnecessary graphics or features that might present challenges for
              users experiencing pain.
            </Typography>
            <Typography
              sx={{
                maxWidth: "80ch",
                lineHeight: 2,
                m: "auto",
                pt: 4,
              }}
            >
              I also wanted the data to make sense to a health care provider if
              a user decided to simply hand their phone over during an
              appointment. This kind of interaction has become incredibly
              common, and it was important for me to think about those users and
              how they might experience this page as well.
            </Typography>
            <Typography
              sx={{
                maxWidth: "80ch",
                lineHeight: 2,
                m: "auto",
                pt: 4,
              }}
            >
              Originally, I'd planned to put the edit and delete buttons in a
              separate component. I was calling this page "AtTheDoctor" and
              thought I would have time to integrate more features. I ultimately
              did not have time to develop those features, and so combined the
              detail/edit view with the chart/"AtTheDoctor" view.
            </Typography>
          </Grid>
        </Grid>
        <Typography
          align="center"
          variant="h2"
          sx={{ mt: 8, mb: 3, bgcolor: "rgb(81,98,98, 0.9)", color: "#f5f5f5", pt:1, pb:1 }}
        >
          What's Next for SymptomTracker?
        </Typography>
        <Typography
          sx={{
            maxWidth: "80ch",
            lineHeight: 2,
            m: "auto",
          }}
        >
          My biggest concern with releasing SymptomTracker is privacy. I used
          best practices with my ERD to protect my users, but it wouldn't take a
          dedicated malefactor long to tie users to the data they're tracking
          should my server become compromised. With that in mind, these are the
          next steps for SymptomTracker before it can be released for use:
        </Typography>
        <Box display="grid" justifyItems="center" sx={{ pb: 6 }}>
          <List>
            <ListItem>
              <ListItemText primary="Rewrite the app using React Native" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Reconfigure the ERD so that symptom logs can be stored locally or in a user's cloud" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Integrate NIH APIs so that users can track medications, providers and healthcare facilites" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Add dark mode/additional accessibility customizations" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Release it!" />
            </ListItem>
          </List>
        </Box>
      </Paper>
    </Container>
  );
}
