// React -------------
import React from "react";

// MUI -------------
import { Typography, Menu, MenuItem, Link, Grid } from "@mui/material";

// assets -------------
import Home from "../../assets/nav/home.png";
import CV from "../../assets/nav/cv.png";
import GitHub from "../../assets/nav/github.png";
import LinkedIn from "../../assets/nav/linkedin.png";
import Projects from "../../assets/nav/projects.png";

// Components and Pages ------------
import "../../styles/nav.css";

export default function Nav() {
  //menu config
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid
      container
      display="flex"
      wrap="nowrap"
      alignItems="start"
      className="nav"
    >
      <Grid item className="nav-box">
        <Link to href="/">
          <img className="nav-icon" src={Home} alt="home" />
        </Link>
        <Typography variant="body1">Home</Typography>
      </Grid>
      <Grid item className="nav-box">
        <Link to href="/resume">
          <img className="nav-icon" src={CV} alt="resume" />
        </Link>
        <Typography variant="body1">About</Typography>
      </Grid>
      <Grid item className="nav-box" onClick={handleClick}>
        <img className="nav-icon" src={Projects} alt="Projects" />
        <Typography variant="body1">Projects</Typography>
      </Grid>
      <Grid item className="nav-box">
        <Link href="https://github.com/MeirSedlis" target="blank">
          <img className="nav-icon" src={GitHub} alt="GitHub" />
        </Link>
        <Typography variant="body1">GitHub</Typography>
      </Grid>
      <Grid item className="nav-box">
        <Link href="https://www.Linkedin.com/in/meirsedlis/" target="blank">
          <img className="nav-icon" src={LinkedIn} alt="LinkedIn" />
        </Link>
        <Typography variant="body1">LinkedIn</Typography>
      </Grid>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem>
          <Link style={{ textDecoration: "none" }} to href="/allyx">
            Allyx
          </Link>
        </MenuItem>
        <MenuItem>
          <Link style={{ textDecoration: "none" }} to href="/symptom-tracker">
            SymptomTracker
          </Link>
        </MenuItem>
      </Menu>
    </Grid>
  );
}
